<template>
<body>
    <div id="home" class="home">
        <div class="text-center">
            <img src="../assets/logo-escrita.png" class="logo img-fluid mt-5"  alt="Logo Night"> 
            <b-container class="box-content mx-auto text-center">
                <h2 class="text-apresentacao"> Você é pessoa certa para conectar 
                    <br>a 
                    <strong class="text-background">Night</strong> 
                    na sua
                    <strong class="text-background">Cidade</strong>
                </h2>
            </b-container>
            <b-container class="d-flex justify-content-center mx-auto">
                <a href="https://apps.apple.com/br/app/nightapp/id1606457140" >
                <img class="img-fluid icons-bibliotecas mt-4" src="../assets/app_store.png" alt="Disponível na App Store">
                </a>
                <a href='https://play.google.com/store/apps/details?id=prod.nightapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img  class="img-fluid google-store" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/>
                </a>
            </b-container>
        </div>
    </div>
        <div id="Sobre">
        <div class="container d-flex justify-content-center mx-auto box-5">
            <b-row class="container d-flex justify-content-center mx-auto mb-5">
                <div class="text-center colum-1" data-aos="fade-up">
                    <img class="img-fluid"  alt="Logo Night" src="../assets/big-logo.png">
                    <h1 class="text-background2">Entregamos para Você</h1>
                </div>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h3 class="box-title">AUXÍLIO NAS VENDAS</h3>
                    <h4> Você terá treinamentos com os Diretores da <b>NightApp</b>
                    e eles serão responsáveis em te ensinar tudo o que precisará saber para se Jogar na <b> Night</b>
                    </h4>
                </b-col>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h3 class="box-title">PLANO DE CRESCIMENTO</h3>
                    <h4>A <b>NightApp</b> é muito transparente e trabalhamos no formato
                     "Ganha Ganha" ou seja, se você se esforçar e tiver resultados temos um plano de crescimento para você
                    </h4>
                </b-col>
                <b-col class="text-center mt-5" data-aos="fade-up">
                    <h3 class="box-title">LUCRATIVIDADE</h3>
                    <h4>O Modelo Digital da Night permite que você 
                        tenha um % do valor geral vendido pela <b>Night</b>, 
                        você gosta de Vender e Lucrar? </h4>
                </b-col>
            </b-row>
        </div>
    </div>
 
    <div id="SeJoga">
        <div class="container d-flex justify-content-center mx-auto box-2">
            <b-row class="container d-flex justify-content-center mx-auto">
                <b-col class="text-center colum-1" data-aos="fade-up">
                    <h1>Se joga na <b>Night</b></h1>
                    <h2>Aqui está um vídeo breve explicando o que 
                        você vai encontrar trabalhando na <b>Night</b>... 
                        está pronto para esse desafio?
                    </h2>
                    <div class=" text-center container d-flex justify-content-center">
                        <a href="https://www.instagram.com/nightbrasil/"><img class=" icon-insta" src="https://img.icons8.com/fluency/96/000000/instagram-new.png"/></a>
                        <a href="https://www.instagram.com/nightbrasil/"><h2 class="insta mt-4">@NIGHTBRASIL</h2></a>
                    </div>
                </b-col>
                <div class="text-center embed-responsive embed-responsive-16by9 col-sm" data-aos="fade-up">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eihD9rPSWlI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>               
                </div>
                <h5 class="text-center mt-5">CONTINUE E SE SURPREENDA</h5>
                <img  class="scroll mb-5"  width="50px" src="../assets/scrolldown.png">
            </b-row>
        </div>
    </div>

    <FormDesktop class="Only-desktop"/>
    <FormMobile  class="Only-mobile"/>

    <div id="Final">
        <b-row class="container d-flex justify-content-center mx-auto box-6"> 
            <b-col>
                 <img src="../assets/logo-escrita.png" class="logo-footer img-fluid  mx-auto mt-5"  alt="Logo Night"> 
            </b-col>
            <b-col class="text-center colum-1" data-aos="fade-up">
                <h1>
                    Entre em contato com a <b>NightApp</b>
                </h1>
                <b-col>
                    <a href="#"><img  class="img-fluid icones" src="../assets/Twitter_white.png"></a>
                    <a href="#"><img  class="img-fluid icones" src="../assets/Face_white.png"></a>
                    <a href="#"><img class="img-fluid icones" src="../assets/Youtube_white.png"></a>
                    <a href="#"><img class="img-fluid icones" src="../assets/instagram_white.png"></a>
                </b-col>
                <b-col>
                    <h2  class="box-title mt-5 p-2">  <a  href="http://suporte.nightapp.me/"> suporte.nightapp.me</a></h2>
                </b-col>
            </b-col>
        </b-row>
    </div>

   <footer  class="bg-dark text-center text-white">
        <div class="text-center p-3">
        © 2022 Copyright: <b>Nightapp.me</b>
        </div>
   </footer>
</body>
</template>
<script>
import FormDesktop from '@/components/FormDesktop.vue'
import FormMobile from '@/components/FormMobile.vue'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export default{
    components:{FormDesktop, FormMobile},
    data() {
        return {
        loaded: false
        }
    },

    
}
</script>
<style>
@media (max-width:600px){
  .Only-desktop{
      display: none !important
    }
}
@media (min-width:600px){
  .Only-mobile{
      display: none !important
    }
}

@import '../styleglobal.css';
</style>