import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
    theme: "outline", 
    position: "top-center", 
    duration: 3000,
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realidada com sucesso!' : payload.msg,
    { type: 'success' }
)

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Oops.. Erro inesperado.' : payload.msg,
    { type : 'error', }
)