<template>
    <div >
        <div class="container d-flex justify-content-center mx-auto box-2">
            <div id="loading-wrapper">
                 <div id="loading-text">            
                   <img src="../assets/big-logo.png" class="logo img-fluid mt-5"  alt="Logo Night"> 
                </div>
                 <div id="loading-content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "loader"
    }
</script>
<style scoped>
  #loading-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index:10000;
    background: #120222;
    
  }

  #loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #5d1a92;
    width: 100px;
    height: 30px;
    margin: -100px 0 0 -45px;
    text-align: center;
    font-size: 15px;
  }

  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid #F00;
  }

  #loading-content {
    border: 3px solid transparent;
    border-top-color: rgb(121, 61, 185);
    border-bottom-color: rgb(121, 61, 185);
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>