<template>
    <div id="Festas">
        <div class="container box-3">
            <b-row>
                <!-- CARD 1 -->
                    <b-col class="text-center mt-5" 
                    data-aos="fade-up"
                    data-aos-duration="4000">
                        <h1><b>Venha ser Promoter</b> <strong class="text-background">Night</strong></h1>
                    </b-col>
                    
                    <b-form class="mx-auto mt-5 mb-5">

                        <b-col>
                            <b-form-group
                            id="input-group-1"
                            label="Nome:"
                            label-for="input-1"
                            >
                                <b-form-input
                                id="input-1"
                                v-model="lead.name"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                            label="Email:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.email"
                                type="email"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-row class="mx-auto">
                            <b-col cols="10">
                                <b-form-group  v-mask="maskTel()"
                                label="Telefone:"
                                label-for="input-"
                                >
                                    <b-form-input
                                    v-model="lead.phone"
                                    type="tel"
                                    v-mask="maskTel()"
                                    required
                                    ></b-form-input>
                                    
                                </b-form-group>
                            </b-col>
                            <b-col cols="2" >
                                <img  class="instagram-icon" src="../assets/Whats_white.png">
                            </b-col>
                        </b-row>

                        <b-row class="mx-auto">
                            <b-col cols="10">
                            <b-form-group
                            label="Instagram:"
                            
                            > 
                                <b-form-input
                                v-model="lead.instagram"
                                type="email" 
                                required
                                > </b-form-input>

                            </b-form-group>
                            </b-col>
                            <b-col cols="2" >
                                <img  class="instagram-icon" src="../assets/instagram_white.png">

                            </b-col>
                        </b-row>

                        <b-col class="text-center">
                            <b-form-group
                            label="Data de Nascimento:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.birthdate"
                                type="date"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>


                    </b-form>

                <!-- CARD 2 -->
                    <b-col class="text-center mt-5" 
                    data-aos="fade-up"
                    data-aos-duration="4000">
                    <h1><b>Falta pouco futuro Promoter</b> <strong class="text-background">Night</strong></h1>
                    </b-col>
                    <b-form class="mb-5">
                        <b-col>
                            <b-form-group
                            label="Cidade:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.city"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                            label="Estado:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.estate"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                            label="Endereço:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.address"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group
                            label="Bairro:"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.province"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                        <b-row class="mx-auto"> 
                            <b-col cols="6">
                            <b-form-group
                            id="input-group-1"
                            label="Número:"
                            label-for="input-2"
                            >
                                <b-form-input
                                id="input-2"
                                v-model="lead.addressNumber"
                                type="text"
                                required
                                ></b-form-input>
                            </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                id="input-group-2"
                                label="CEP:"
                                label-for="input-2"
                                >
                                    <b-form-input
                                    id="input-2"
                                    v-model="lead.postalCode"
                                    type="number"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        

                    </b-form>
                <!-- CARD 3-->
                    <b-form class="mt-5 mb-5">
                        <b-col>
                            <b-form-group
                            label="Qual sua experiência com eventos?"
                            label-for="input-"
                            >
                                <b-form-textarea
                                v-model="lead.question1"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                            label="Já foi em vários eventos e festas? Se sim, fale um pouco"
                            label-for="input-"
                            >
                                <b-form-textarea
                                v-model="lead.question2"
                                rows="4"
                                max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                            label="Tem emprego fixo?"
                            label-for="input-"
                            >
                                <b-form-input
                                v-model="lead.question3"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>

                    </b-form>

                <!-- CARD 4-->

                    <b-form class="mt-5">
                        <b-col>
                            <b-form-group
                            label="Qual sua facilidade em falar com donos ou Promoters de Casas de festas, bares, restaurantes e etc?"
                            label-for="input-"
                            >
                                
                                <b-form-textarea
                                v-model="lead.question4"
                                rows="4"
                                max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                            label="Tem empresa ou já teve empresa aberta?"
                            label-for="input-"
                            >
                            <b-form-input
                                v-model="lead.question5"
                                type="text"
                                required
                                ></b-form-input>
                                
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                            label="Caso seja selecionado, quantas horas por dia você terá para se dedicar a Night? Fale um Pouco o motivo de ser você o Promoter Night em sua cidade..."
                            label-for="input-"
                            >
                                <b-form-textarea
                                v-model="lead.question6"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                                
                            </b-form-group>
                        </b-col>

                    </b-form>  
                    <b-alert v-if=" sucess == true" variant="success" show>Cadastro realizado com sucesso!</b-alert>                 
                    <b-col class="text-center mt-5 mb-5">
                        <b-button 
                        class="text-center mx-auto"
                        @click="save() ">
                        <h5 v-if="loading == false">Salvar</h5>
                        <b-spinner  v-if="loading == true" type="grow" label="Loading..."></b-spinner>

                        </b-button>
                        
                    </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import { baseApiUrl, showError, showSucess } from '@/global'

import axios from 'axios'
  export default {
    data() {
        return{
            card: 1,
            sucess: false,
            loading: false,
            lead:{
                name: null,
                email: null,
                address: null,
                addressNumber:null,
                province: null,
                postalCode:null,
                city: null,
                estate: null,
                birthdate: "",
                phone: "",
                instagram: null,
                question1: null,
                question2: null,
                question3: null,
                question4: null,
                question5: null,
                question6: null,
                ative: true
            }
        }

    },
    methods: {
        save(){
            axios.post(`${baseApiUrl}/lead`, this.lead)
            .then((res) => {
                if(res.status === 204){
                    this.sucess = true
                    this.loading = false
                    showSucess(res)
                }
            }).catch(this.loading = true)
            .catch((error) => {
                showError(error)
                this.loading = false
            });
        },
        maskTel(){
            if ( this.lead.phone.length == 15 ){
              return this.lead.phone.length  == 15 ? '(##) #####-####' : '(##) ####-#####'
            } else {
                return '(##) ####-####'
            }
        },

        

    }
  }
</script>

<style>
@import '../styleglobal.css';
</style>