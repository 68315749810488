<template>
  <div id="app">
    <div>
      <transition name="slide-fade" mode="out-in">
        <HomePage/>
      </transition>
    </div>
    <transition name="slide-fade" mode="out-in">
     <Loader v-if="loading"/>
     </transition>

  </div>
</template>

<script>
import Loader from "@/components/Loader.vue"

import HomePage from '@/components/HomePage'
export default {
  name: 'App',
  components: {
    Loader,
    HomePage
  },
    data: () => ({
    loading: true
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 2000)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
